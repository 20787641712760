.szoveg {

	h1, h2, h3,	h4, h5, h6 {
		//font-weight: 500;
	}

	p {
		margin-bottom: 1rem;
		font-size: $font-size-base;
	}

	ul, ol {
		list-style-position: inside;
		padding-left: 25px;
	}

	ul {
		//list-style: none;
		> li {
			position: relative;
			/*
			padding-left: 20px;

			&:before {
				content: '';
				width: 10px;
				height: $line-height-base * $font-size-base / 2;
				border-bottom: 1px solid $body-color;
				background: transparent;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}
			*/
		}
	}

	img {
		max-width: 100%;
		@extend .img-fluid;
	}

	figure {
		margin-bottom: 15px;
	}
	figcaption {
		color: $body-color;
		font-size: 14px;
	}

	table {
		width: 100%;
		border: 0;
		margin-bottom: 15px;
		thead {
			> tr {
				background-color: $primary;
				border: 1px solid $body-color;
				color: white;
				> th {
					text-align: center;
					border: 1px solid $body-color;
					vertical-align: middle;
					padding: 5px 0;
				}
			}
		}

		tbody {
			> tr {
				border: 1px solid $body-color;
				> td, th {
					border: 1px solid $body-color;
					padding: 2px 0;
					vertical-align: middle;
					color: $body-color;
					text-align: center;
				}
			}
			> tr:nth-child(odd) {
				background-color: transparent;
			}

			> tr:nth-child(even) {
				background-color: rgba(217, 217, 217, 0.3);
			}
		}
	}

	blockquote {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		border-color: $primary;
		color: $primary;
		text-transform: uppercase;
		font-size: $font-size-base;
	}

	hr {
		border-color: #7a7a7a;
		border-bottom: 2px;
	}

}