.fooldal-hero {
	height: calc(100vh - 100px);
	color: $white;

	&-title {
		line-height: 1;
	}

	&-lead {
		font-size: 1.125rem;
		max-width: 800px;
		margin-bottom: 2rem;
	}

	&-nyil {
		margin-left: -.75rem;
		cursor: pointer;

		&:hover {
			transform: translate(5px, 5px);
			@include default-transition;
		}
	}
}

.fooldal-rolunk {
	&-card {
		position: relative;
		z-index: 10;
	}

	&-picture-wrap {
		position: relative;
		z-index: 5;
	}
}

.fooldal-ref {
	&-card {
		position: relative;
		z-index: 10;
	}

	&-picture-wrap {
		position: relative;
		z-index: 5;
	}

	&-link {
		&:hover,
		&:focus {
			transform: translateX(5px);
		}
	}
}

.fooldal-kapcsolat {
	background: $primary url(../../images/bg-kapcsolat.jpg) 100% 50% no-repeat;
	background-size: auto 100%;
}