@include media-breakpoint-down(md){
	body {
		background-size: auto;
		background-position: 50% -80vh;
	}

	.fooldal-hero-title {
		font-size: 2rem;
		line-height: 1.36;
	}

	.fooldal-ref-picture-wrap,
	.fooldal-rolunk-picture-wrap {
		padding: 0;
		margin-left: $grid-gutter-width / -2;
		margin-right: $grid-gutter-width / -2;
		width: 100vw;
	}

	.fooldal-ref-card {
		margin-left: $grid-gutter-width / 2;
		margin-right: $grid-gutter-width / 2;
	}

	.fooldal-partnerek-link {
		flex-basis: 50%;
		padding: 2rem 0;
		text-align: center;
	}
}