.header {
	height: 100px;
}

.menu-toggle {
	display: none;
}

.menu-fomenulink {
	margin: 0 1.125rem;
	color: $white;
	text-transform: lowercase;
	position: relative;

	&.active::after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 3px;
		background: $secondary;
	}
}

@include media-breakpoint-down(sm) {
	.menu-toggle {
		display: flex;
		margin-left: 1rem;
		background: transparent;
		border: 0;
		padding: 0;
		z-index: 910;
		position: relative;

		&:focus {
			outline: none;
		}

		&.open {
			.bar {
				top: 50%;
				right: -10%;
				left: -10%;
			}

			.bar-1 {
				transform: rotate(45deg);
			}

			.bar-2 {
				transform: rotate(-45deg);
			}

			.bar-3 {
				opacity: 0;
			}

			.menu-toggle-close {
				transform: rotateX(0);
			}

			.menu-toggle-open {
				transform: rotateX(90deg);
			}
		}
	}

	.menu-toggle-close {
		display: block;
		position: absolute;
		@include default-transition;
		transform-origin: 50% 50%;
		transform: rotateX(90deg);
	}

	.menu-toggle-open {
		display: block;
		position: absolute;
		@include default-transition;
		transform-origin: 50% 50%;
		transform: rotateX(0);
	}

	.menu-toggle-icon {
		font-size: 15px;
		width: 1.3em;
		height: 1em;
		position: relative;
		margin-right: 20px;

		.bar {
			background: #fff;
			position: absolute;
			display: block;
			height: 2px;
			left: 0;
			border-radius: .055em;
			transform-origin: 50% 50%;
			margin-top: -.055em;
			@include default-transition;
		}

		.bar-1 {
			top: 0;
			left: 2px;
			right: -2px;
		}

		.bar-2 {
			top: 50%;
			left: 4px;
			right: -4px;
		}

		.bar-3 {
			opacity: 1;
			bottom: 0;
			right: 0;
		}
	}

	.menu {
		color: #fff;
		width: 85vw;
		position: fixed;
		top: 0;
		left: -100vw;
		bottom: 0;
		z-index: 900;
		background: $primary;
		padding: 70px 2rem 2rem;
		font-size: 10px;
		font-family: $headings-font-family;
		@include default-transition;

		&.open {
			left: 0;
		}

		a {
			color: #fff;

			&.active,
			&:focus, &:hover {
				//color: $primary;
			}
		}
	}

	.menu-fomenulink {
		margin: 20px 0;
		display: block;
		font-size: 1.4rem;

		&.active:after {
			right: auto;
			top: 0;
			left: -2rem;
			bottom: 0;
			width: 3px;
			height: auto;
		}
	}
}