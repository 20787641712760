@mixin default-transition() {
	@include transition(all .25s ease-in-out);
}

html, body {
	height: 100%;
}

body {
	background-image: url(../../images/hero-bg.jpg?v2);
	background-position: 50% -48vw;
	background-repeat: no-repeat;
	background-size: 100vw auto;

	&.page-fooldal {
		background-position: 50% 0;
	}

	&:not(.page-fooldal) main.container {
		background: $white;
		margin-top: 2rem;
		margin-bottom: 2rem;
		padding: $grid-gutter-width;
	}
}

a {
	@include default-transition;
}

a.inverz {
	color: $body-color;

	&:focus, &:hover {
		color: $primary;
	}
}

body:not(.page-fooldal)>main {
	margin-top: 110px;
	margin-bottom: 110px;
}

.bovebben-link {
	background: 0;
	border: 0;
	padding: 0;
	font-size: 12px;

	>svg {
		margin-left: 5px;

		line, path {
			@include default-transition;
		}
	}

	a:hover &>svg {
		line, path {
			stroke: $primary;
		}
	}
}

.cikk-ajanlo-cim {
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
	text-transform: uppercase;
}

.cikk-ajanlo-lead {
	line-height: 25px;
}


.pagination {
	> li > span,
	> li > a {
		border-radius: 17px !important;
		border-color: transparent;
		margin: 0 4px;
	}

	> li > a {
		color: $body-color;

		&:hover, &:focus {
			color: $primary !important;
			border-color: $primary;
		}
	}

	> li.active > span,
	> li.active > span:hover,
	> li > a:hover,
	> li > a:focus {
		background: transparent;
		color: $body-color;
	}
}

input[type=checkbox] {
	opacity: 0;
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 0;
	height: 0;

	+ label {
		padding-left: 35px;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 18px;
			height: 18px;
			border-radius: 3px;
			border: solid 1px $primary;
			color: $primary;
			text-align: center;
			line-height: 18px;
		}
	}

	&:checked + label::before {
		content: '\2714';
	}
}

.swal-button {
	background-color: $primary !important;
}

@-webkit-keyframes rotating {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(-360deg);
		-o-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(-360deg);
		-moz-transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
		-o-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

.rotating {
	-webkit-animation: rotating 1s linear infinite;
	-moz-animation: rotating 1s linear infinite;
	-ms-animation: rotating 1s linear infinite;
	-o-animation: rotating 1s linear infinite;
	animation: rotating 1s linear infinite;
}

ul {
	padding-left: 1rem;
}

.text-gray-500 {
	color: $gray-500 !important;
}

.footer {
	background: $gray-150;
}

.page-header {
	margin: 1rem 0 2rem;
	text-align: center;
	text-transform: lowercase;

	&::after {
		content: '';
		display: block;
		margin: .75rem auto;
		height: 3px;
		width: 3rem;
		background: $secondary;
	}
}

.hirek-listaelem {
	&-cim {
		@include default-transition;
	}

	&-link:hover &-cim,
	&-link:active:focus &-cim {
		color: $secondary;
	}
}
