@import url('//fonts.googleapis.com/css?family=Montserrat:400,500,600,700&amp;subset=latin-ext');

@import "../../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "animate";
@import "cookie-consent";

@import "jquery.fancybox";

@import "layout";
@import "header";
@import "szoveg";
@import "fooldal";

@import "app-md";
@import "app-sm";